import React from "react";
import LooseDiamondsCountry from "../../components/FrontStatic/WholesaleLooseDiamonds/Country";
import {graphql} from "gatsby";
import {connect} from "react-redux";
import SEO from "../../components/SEO";

const CountryComponent = (props) => <React.Fragment>
    <SEO {...(props?.data?.seo?.frontmatter || {})}/>
    <LooseDiamondsCountry
    {...props}
    fields={props.data}
    country="AU"
/></React.Fragment>;

const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, null)(CountryComponent);
export const query = graphql`
 fragment AUFragment on MarkdownRemark {
      frontmatter {
      title
      description
    }
  }
  query AUPageQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "wld-au-seo"}}) {
        frontmatter {
            title
            description
        }
  }
  enAU: markdownRemark(frontmatter: {templateKey: {eq: "en-wld-au"}}) {
    ...AUFragment
  }
  deAU: markdownRemark(frontmatter: {templateKey: {eq: "de-wld-au"}}) {
    ...AUFragment
  }
  esAU: markdownRemark(frontmatter: {templateKey: {eq: "es-wld-au"}}) {
    ...AUFragment
  }
  frAU: markdownRemark(frontmatter: {templateKey: {eq: "fr-wld-au"}}) {
    ...AUFragment
  }
  heAU: markdownRemark(frontmatter: {templateKey: {eq: "he-wld-au"}}) {
    ...AUFragment
  }
  itAU: markdownRemark(frontmatter: {templateKey: {eq: "it-wld-au"}}) {
    ...AUFragment
  }
  jaAU: markdownRemark(frontmatter: {templateKey: {eq: "ja-wld-au"}}) {
    ...AUFragment
  }
  zhAU: markdownRemark(frontmatter: {templateKey: {eq: "zh-wld-au"}}) {
    ...AUFragment
  }
 }
`;
