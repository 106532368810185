import React, { Component } from "react";
import FrontFooter from "../common/Front-Footer";
import FrontWrapper from '../common/FrontWrapper'
import {goToLink} from "../../../services/helper";

class Country extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { locale } = this.props.commonData.locale;
		console.log("+++++++++++++++",this.props?.fields?.[`${locale}${this.props.country}`]);
		const fields = this.props?.fields?.[`${locale}${this.props.country}`]?.frontmatter;
		console.log("-=================", fields)
		return (
			<FrontWrapper location={this.props.location} isForDevice={this.props.isForDevice}>
			<div className="scroll-content-wrapper" data-scrollbar>
				<div className="page-main page-current">
					<div className="page-toload legacy-page" data-bodyclassname="legacy">
						<main className="page-content" role="main">
							<section className="splash term-con relative mb-50 mb-xs-0 removeImg">
								<div className=" container sitemap-container">
									<h1 className="primary-text">{fields?.title}</h1>
								</div>
							</section>

							<section className="mt-xs-20" style={{margin:'0px'}}>
								<div className="container sitemap-container">
									<div className="d-flex flex-wrap-xs">
										<div className="">
											<div className="term-content  mb-50 mb-xs-20">
												<div dangerouslySetInnerHTML={{__html: fields?.description}} />
												<a onClick={()=>goToLink("signup")} className="btn btn-primary btn-small">Register</a>
											</div>

										</div>


									</div>
								</div>
							</section>

							{!this.props.isForDevice && <FrontFooter /> }
						</main>
						<div className="preload" aria-hidden="true" />
					</div>
				</div>
				<div className="page-main page-next" aria-hidden="true" />
				<div className="page-main page-prev" aria-hidden="true" />
				<footer className="footer" role="contentinfo" />

			</div>
			</FrontWrapper>
		);
	}
}

export default Country;
